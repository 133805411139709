<template>
  <a class="morebtn mb100" v-if="pageNum < totalPage" @click="goToPage(pageNum + 1)">{{$t('front.board.more')}}</a>
  <!--div class="paging"-->
    <!--a class="ft18" @click="goToPage(navMin)">«</a-->
    <!--a @click="goToPage(pageNum - 1)"><img src="@/assets_mobile/img/arrowL.png" /></a>
    <template v-for="i in navArray" v-bind:key="`nav${i}`">
      <a :class="{ on: pageNum === i }"
          @click="goToPage(i)">{{i}}</a>
    </template>
    <a @click="goToPage(pageNum + 1)"><img src="@/assets_mobile/img/arrowR.png" /></a-->
    <!--a class="ft18" @click="goToPage(navMax)">»</a-->
  <!--/div-->
</template>

<script>
import Pagination from '@/components/ui/Pagination'

export default {
  name: 'PaginationMobile',
  mixins: [Pagination],
  created () {
    console.log(this.totalCount, this.totalPage, this.pageNum)
  }
}
</script>

<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
